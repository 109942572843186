.video-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.fullscreen-video {
  margin-top: 175px;
}

.fullscreen-video2 {
  margin-top: 175px;
  width: 100%;
  height: 100%;
  max-width: 700px;
}

.clickable-area {
  position: absolute;
  top: 35%;
  left: 50%;
  width: 350px;
  height: 200px;
  transform: translate(10%, 30%);
  /* Adjust the width, height, and position as needed */
  /* Add background, styles, or content as desired */
}

.overlay {
  position: absolute;
  top: 575px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay-gif {
  width: 200px; /* Adjust the width as needed */
  height: auto;
}

.clickable-area-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 125px;
  transform: translate(-50%, 150%);
  /* Adjust the width, height, and position as needed */
  /* Add background, styles, or content as desired */
}

@media (max-width: 1000px) {
  .fullscreen-video {
    margin-top: 175px;
    width: 100%;
    height: 100%;
  }
  
}
